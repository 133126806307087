export const clientNameFooter = "YOGATHAI";
export const titleMain = "Bem vindo ao Yogathai";
export const subtitleMain = "Sua jornada de encontro com a sua Força.";

export const aspectRatioLogoAbout = '1'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "YOGATHAI";
export const nomeFornecedor = "YOGATHAI";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://instagram.com/yogathaibr?igshid=MzRlODBiNWFlZA==",
    profile: "@yogathaibr"
  },
  // {
  //   type: "material-community",
  //   name: "youtube",
  //   link: "https://www.youtube.com",
  // },
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://api.whatsapp.com/send/?phone=5531985367070&text&type=phone_number&app_absent=0",
    number: "(31) 98536-7070"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://api.whatsapp.com/send/?phone=5531985367070&text&type=phone_number&app_absent=0",
  },
];

export const linkAppleStore = "https://apps.apple.com/us/app/yogathai/id6472718320?platform=iphone"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=app.web.mobile_yogathai.twa"

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];